export const theme = {
    bgColor: '#FFF',
    white: '#fff',
    red: '#df1a29',
    darkRed: '#b4131f',
    grey: '#6B6B6B',
    lightGrey: '#7e7e7e',
    black: '#000000',
    lightBlue: '#21ACFF',
    darkText: '#000',
    fontFamily: 'Source Sans Pro, sans-serif',
    fontFamily_Roboto: "'Roboto', sans-serif",
}
