import styled from "styled-components"

export const StyledLine = styled.div`
    background-color: ${props => props.bgColor};
    width: ${props => props.angle === "0" ? props.w : StyledLine.defaultProps.w};
    height: ${props => props.h};
    transform: rotate(${props => props.angle}deg) ;
    transform-origin: 1px;
    transition: all 0.3s linear;
  `

StyledLine.defaultProps = {
  bgColor: "#fff",
  w: "45px",
  h: "2px",
  angle: "0"
}
