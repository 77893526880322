import React from 'react'
import styled from 'styled-components'
import { screenSizes } from '../../lib/constants'
import PropTypes from 'prop-types'
import { theme } from '../../theme'

const Title = styled.div`
    font-weight: 900;
    font-size: ${(props) => props.fontSize};
    color: ${({ theme }) => theme.white};
    text-transform: uppercase;

    @media (max-width: ${screenSizes.md}px) {
    }
`

const TitleFirstPart = styled.div`
    display: block;
    width: 100%;
    font-size: 3rem;
    z-index: 1;
    color: ${(props) => props.mainColor};

    @media (max-width: ${screenSizes.xl}px) {
        font-size: 2.4rem;
    }

    @media (max-width: ${screenSizes.lg}px) {
        font-size: 2.2rem;
    }
`

const TitleSecondPart = styled.div`
    display: block;
    width: 100%;
    //color: transparent;
    //-webkit-text-stroke: 0.03em ${(props) => props.secColor};
    color: ${(props) => props.secColor};
    font-size: 2rem;
    letter-spacing: 2px;
    margin-top: -0.6em;
    z-index: 2;

    @media (max-width: ${screenSizes.xl}px) {
        font-size: 2.4rem;
    }

    @media (max-width: ${screenSizes.lg}px) {
        font-size: 2.2rem;
    }
`

const stringWithoutFirstWord = (str) => {
    const indexOfSpace = str.indexOf(' ')

    if (indexOfSpace === -1) {
        return ''
    }

    return str.substring(indexOfSpace + 1)
}

const firstWordOfString = (str) => {
    return typeof str.split(' ')[0] !== 'undefined' ? str.split(' ')[0] : str
}

const InnerTitle = (props) => {
    const first = firstWordOfString(props.title)
    const second = stringWithoutFirstWord(props.title)

    return (
        <Title {...props}>
            <TitleFirstPart {...props}>{first}</TitleFirstPart>
            <TitleSecondPart {...props}>{second}</TitleSecondPart>
        </Title>
    )
}

InnerTitle.propTypes = {
    title: PropTypes.string,
    mainColor: PropTypes.string,
    secColor: PropTypes.string,
    fontSize: PropTypes.string,
}

InnerTitle.defaultProps = {
    title: 'Title Placeholder',
    mainColor: theme.red,
    secColor: theme.black,
    fontSize: '2rem',
}

export default InnerTitle
