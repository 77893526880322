import styled from 'styled-components'
import { screenSizes } from '../../lib/constants'

const Wrapper = styled.div`
    margin: 0 auto;
    max-width: ${screenSizes.xl}px;
    display: block;
    position: relative;
    width: 100%;
    //overflow-x: hidden;

    @media (max-width: ${screenSizes.xl}px) {
        max-width: ${screenSizes.lg}px;
    }

    @media (max-width: ${screenSizes.lg}px) {
        max-width: ${screenSizes.md}px;
    }

    @media (max-width: 768px) {
        max-width: calc(100% - 20px);
    }
`

export default Wrapper
