import React from 'react'
import LogoName from './logo.svg'
import LogoIcon from './logoicon.svg'
import { useTranslation } from 'react-i18next'

const Logo = (props) => {
    const { i18n } = useTranslation()

    return (
        <a href={`/?lang=${i18n.language}`}>
            <img className="" src={LogoName} alt="" width={props.width} />
        </a>
    )
}

Logo.defaultProps = {
    width: '200px',
}

export default Logo
