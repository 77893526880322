import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Spacer from '../../uielements/Spacer'
import Text from '../../uielements/Text'
import { theme } from '../../theme'

const UnorderedList = styled.div`
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const ListItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 0;
    position: relative;
    margin: 0 10px;

    @media (max-width: 1200px) {
        padding: 4px 4px 4px 0;
        margin: 0 4px;
    }

    a {
        color: ${theme.red};
        text-decoration: none;
        font-weight: bold;
        padding: 5px 10px 5px 0;

        &:hover {
            text-decoration: none;
        }
    }

    div {
        position: absolute;
        left: 0;
        top: 100%;
        transition: all 0.2s linear;
    }

    &:hover {
        div {
            top: 0;
        }
    }
`

const TopMenu = (props) => {
    return (
        <UnorderedList className="top-menu">
            <ListItem></ListItem>
            <Spacer />
        </UnorderedList>
    )
}

export default TopMenu
