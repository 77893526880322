import styled from "styled-components"
import PropTypes from "prop-types"

const Text = styled.span`
  text-transform: ${(props) => props.tt};
  font-weight: ${(props) => props.fw};
  font-size: ${(props) => props.fs}em;
  color: ${(props) => props.textColor};
  
  @media (max-width: 1200px) {
    font-size: ${(props) => 0.9*props.fs}em;
  }
`

Text.defaultProps = {
  tt: "initial",
  fw: "normal",
  fs: "1",
  textColor: "#000",
}

Text.propTypes = {
  tt: PropTypes.string,
  fw: PropTypes.string,
  fs: PropTypes.string,
  textColor: PropTypes.string,
}

export default Text
