import styled from 'styled-components'
import PropTypes from 'prop-types'

const Split = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${(props)=> props.justify};
  flex-direction: ${(props)=> props.direction};
  align-items: ${(props)=> props.align};
`

Split.defaultProps = {
  justify: 'flex-start',
  direction: 'row',
  align: 'center'
}

Split.propTypes = {
  justify: PropTypes.string,
  direction: PropTypes.string,
  align: PropTypes.string,
}

export default Split
