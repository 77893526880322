import { css } from "styled-components"
import { screenSizes } from "./constants"


// window media queries
const small = `(max-width: ${screenSizes.sm}px)`
const smallOrMedium = `(max-width: ${screenSizes.lg}px)`
const large = `(min-width: ${screenSizes.lg + 1}px)`
const xlarge = `(min-width: ${screenSizes.xl + 1}px)`

// devices media queries
const smallScreen = `(max-width: ${screenSizes.sm}px) and (max-device-width: ${screenSizes.sm}px)`

const smallOrMediumScreen = `(max-width: ${screenSizes.lg}px) and (max-device-width: ${screenSizes.lg}px)`

const largeScreen = `(min-width: ${screenSizes.lg +
1}px) and (min-device-width: ${screenSizes.lg + 1}px)`

const xlargeScreen = `(min-width: ${screenSizes.xl +
1}px) and (min-device-width: ${screenSizes.xl + 1}px)`

export const queries = {
  smallOrMedium,
  small,
  large,
  xlarge,
  smallScreen,
  smallOrMediumScreen,
  largeScreen,
  xlargeScreen
}

// iterate through the sizes and create a media template
const mediaQueries = Object.keys(screenSizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = screenSizes[label] / 16

  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})


/**
 * Call the styles factory for with the correct props for each media query
 * @param {function} stylesGenerator function that generates the styles
 *
 * @returns {array} array of styles to be applied for the registered media queries
 */
export const generateResponsiveStyles = stylesGenerator => (props) => {
  return Object.keys(mediaQueries).reduce((rules, mq) => {

    if (!props[mq]) return rules

    const styles = mediaQueries[mq]`
    ${stylesGenerator(props[mq])}
  `
    return [...rules, styles]
  }, [])

}


// Ref here
// https://dev.to/jepser/2-functions-that-made-my-components-responsive-10fb
