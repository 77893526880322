import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { theme } from '../../theme'
import RafaLift from './rafalift.jpg'
import { screenSizes } from '../../lib/constants'

const BgImageWrapper = styled.div`
    width: 100vw;
    height: ${({ size }) => (size === 'half' && '50vh') || '100vh'};
    background-image: url(${(props) => props.bgImg});
    background-position: center;
    background-size: cover;
    position: relative;

    h1 {
        font-size: 3rem;
        margin: 10px auto;
        line-height: 1;

        @media (max-width: ${screenSizes.md}px) {
            font-size: 2rem;
        }

        @media (max-width: ${screenSizes.sm}px) {
            font-size: 1.4rem;
        }
    }
`

const BigText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: ${({ size }) => (size === 'half' && '6vw') || '12vw'};
    z-index: 2;
    font-weight: bold;
    color: ${theme.red};

    @media (max-width: ${screenSizes.lg}px) {
        font-size: ${({ size }) => (size === 'half' && '12vw') || '20vw'};
    }

    @media (max-width: ${screenSizes.md}px) {
        font-size: ${({ size }) => (size === 'half' && '12vw') || '20vw'};
    }

    @media (max-width: ${screenSizes.sm}px) {
        top: 20%;
        font-size: ${({ size }) => (size === 'half' && '10vw') || '25.5vw'};
    }
`

const BigTextBg = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-47%) translateX(-48%);
    font-size: ${({ size }) => (size === 'half' && '6vw') || '12vw'};
    z-index: 2;
    font-weight: bold;
    -webkit-text-stroke: 1px ${theme.lightGrey};
    color: transparent;

    @media (max-width: ${screenSizes.lg}px) {
        font-size: ${({ size }) => (size === 'half' && '12vw') || '20vw'};
    }

    @media (max-width: ${screenSizes.md}px) {
        font-size: ${({ size }) => (size === 'half' && '12vw') || '20vw'};
    }

    @media (max-width: ${screenSizes.sm}px) {
        top: 20%;
        font-size: ${({ size }) => (size === 'half' && '10vw') || '25.5vw'};
    }
`

const Overlay = styled.div`
    width: 100vw;
    height: ${({ size }) => (size === 'half' && '50vh') || '100vh'};
    background: rgba(0, 0, 0, 0.45);
    z-index: 1;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`

const ParagraphText = styled.div`
    transform: translateY(-50%) translateX(-50%);
    color: ${theme.white};
    font-size: 1.4rem;
    text-align: center;
    max-width: 40vw;
    width: 100%;
    line-height: 1.2;
    position: absolute;
    top: 50%;
    left: 50%;
    display: ${({ size }) => (size === 'half' && 'none') || 'block'};

    @media (max-width: ${screenSizes.lg}px) {
        max-width: 90%;
        width: 100%;
        font-size: 1.2rem;
    }

    @media (max-width: ${screenSizes.md}px) {
        max-width: 90%;
        width: 100%;
        font-size: 1.2rem;
    }

    @media (max-width: ${screenSizes.sm}px) {
        font-size: 1rem;
    }
`

const CommonWrapper = styled.div`
    z-index: 2;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100vw;
    height: ${({ size }) => (size === 'half' && '50vh') || '100vh'};
    position: relative;
`
const TopWrapper = styled.div`
    position: relative;
`

const BottomWrapper = styled.div`
    position: relative;
    display: ${({ size }) => (size === 'half' && 'none') || 'block'};
`

const BgImage = (props) => {
    return (
        <BgImageWrapper {...props}>
            <CommonWrapper size={props.size}>
                <TopWrapper>
                    <BigTextBg size={props.size}>{props.txt}</BigTextBg>
                    <BigText size={props.size}>{props.txt}</BigText>
                </TopWrapper>
                <BottomWrapper size={props.size}>
                    <ParagraphText>{props.children}</ParagraphText>
                </BottomWrapper>
            </CommonWrapper>
            <Overlay size={props.size} />
        </BgImageWrapper>
    )
}

BgImage.propTypes = {
    bgImg: PropTypes.string,
    txt: PropTypes.string,
    size: PropTypes.string,
}

BgImage.defaultProps = {
    bgImg: RafaLift,
    txt: 'Main text',
    size: 'full',
}

export default BgImage
