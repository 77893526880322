import styled from 'styled-components'
import PropTypes from 'prop-types'
import { css } from 'styled-components'
import { unitize } from '../../utils/utils'
import { generateResponsiveStyles } from '../../lib/media'

const baseStyles = ({ top, left, right, bottom }) => css`
    margin-top: ${top ? unitize(top) : 0};
    margin-right: ${right ? unitize(right) : 0};
    margin-bottom: ${bottom ? unitize(bottom) : 0};
    margin-left: ${left ? unitize(left) : 0};
`

// define the styling function for the responsive props
const responsiveStyles = generateResponsiveStyles(baseStyles)

const Spacer = styled.div`
    ${baseStyles} // the base styles
  ${responsiveStyles} // the responsive styles
`

Spacer.defaultProps = {
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
}

Spacer.propTypes = {
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
}

export default Spacer
