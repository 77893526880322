import { createGlobalStyle } from 'styled-components'
import { screenSizes } from './lib/constants'
import { theme } from './theme'

export const GlobalStyles = createGlobalStyle`  
  html, body {
    margin: 0;
    padding: 0;
  }
  *, *::after, *::before {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
    
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0; 
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  :focus {
      outline: 0;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
    font-family: ${theme.fontFamily};
    font-size: calc(16px + 2*(100vw - 300px)/1300);
  }
  
  h1{
    font-size: 1.6rem;
  }
  
  h2{
    font-size: 1.4rem;
  }
  
  h3 {
      font-size: 1.2rem;
  }
  
  h4{
    font-size: 1.1rem;
  }
  
  h5{
    font-size: 1rem;
  }
  
  h6 {
      font-size: 0.9rem;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }
  
  strong {
    font-weight: bold;
  }
  
  em {
    font-style: italic;
  }

  .fullHeight {
    height: 100%
  }
  
  .particlesCanvas{
      position: absolute !important;
  }
  
  .fixed {
    position: fixed !important;
    left:0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 99;
    overflow: scroll;
  }

  .noScroll {
    overflow: hidden;
  }

  .scrollable {
    //overflow: auto;
    pointer-events: all;
  }

  ::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }

  ::-webkit-scrollbar-button {
    width: 1px;
    height: 1px;
  }

  .slick-prev {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    width: 40px;
    height: 40px;
  }

  .slick-next {
    right: 10px;
    z-index: 9;
    width: 40px;
    height: 40px;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 40px;
    color: ${theme.red};
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  input[type=search]::-webkit-search-cancel-button,
  input[type=search]::-webkit-search-decoration,
  input[type=search]::-webkit-search-results-button,
  input[type=search]::-webkit-search-results-decoration {
      -webkit-appearance: none;
      -moz-appearance: none;
  }

  input[type=search] {
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
  }

  textarea {
      overflow: auto;
      vertical-align: top;
      resize: vertical;
  }

  audio,
  canvas,
  video {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      max-width: 100%;
  }

  audio:not([controls]) {
      display: none;
      height: 0;
  }

  [hidden] {
      display: none;
  }

  html {
      font-size: 100%; 
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
  }

  a:focus {
      outline: thin dotted;
  }
  
  a.inlineLink {
    font-weight:bold;
    text-decoration: none;
    border-bottom: 1px dashed #ccc;
    color: ${theme.grey};
    transition: all 0.7s linear;
    
    &:hover{
        color: ${theme.red};
    }
  }

  a:active,
  a:hover {
      outline: 0;
  }

  img {
      border: 0;
      -ms-interpolation-mode: bicubic;
      max-width: 100%;
  }

  figure {
      margin: 0;
  }
  
  form {
      margin: 0;
  }

  fieldset {
      border: 1px solid #c0c0c0;
      margin: 0 2px;
      padding: 0.35em 0.625em 0.75em;
  }

  legend {
      border: 0;
      padding: 0;
      white-space: normal;
      *margin-left: -7px;
  }

  button,
  input,
  select,
  textarea {
      font-size: 100%;
      margin: 0;
      vertical-align: baseline;
      *vertical-align: middle;
  }

  button,
  input {
      line-height: normal;
  }

  button,
  select {
      text-transform: none;
  }
  
  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
      -webkit-appearance: button;
      cursor: pointer; 
      *overflow: visible;
  }

  button[disabled],
  html input[disabled] {
      cursor: default;
  }

  input[type="checkbox"],
  input[type="radio"] {
      box-sizing: border-box;
      padding: 0;
      *height: 13px;
      *width: 13px;
  }

  input[type="search"] {
      -webkit-appearance: textfield;
      -moz-box-sizing: content-box;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
      border: 0;
      padding: 0;
  }

  textarea {
      overflow: auto;
      vertical-align: top;
  }

  table {
      border-collapse: collapse;
      border-spacing: 0;
  }

  html,
  button,
  input,
  select,
  textarea {
      color: #222;
  }

  ::-moz-selection {
      background: #df1a29;
      text-shadow: none;
  }

  ::selection {
      background: #df1a29;
      text-shadow: none;
  }
  
  img {
      vertical-align: middle;
  }

  fieldset {
      border: 0;
      margin: 0;
      padding: 0;
  }

  textarea {
      resize: vertical;
  }
  
  .chromeframe {
      margin: 0.2em 0;
      background: #ccc;
      color: #000;
      padding: 0.2em 0;
  }
  
  b {
    font-weight: bold
  }
  
  .no-overflow {
    overflow: hidden;
  }
  
  .wrapper {
    margin: 0 auto;
    max-width: 1170px;
    display: block;
    overflow-x: hidden;
  
    @media (max-width: 1200px) {
        max-width: 962px;
    }
  
    @media (max-width: 992px) {
        max-width: 738px;
    }
  
    @media (max-width: 768px) {
        max-width: calc(100% - 20px);
    }
  }
  
  @media (max-width: ${screenSizes.sm}px) {
    .hidden-sm {
      display: none !important;
    }
  }
 
  @media only screen and (min-width:${screenSizes.sm}px) and (max-width: ${screenSizes.md}px) {
    .hidden-md {
      display: none !important;
    }
  }
  
  @media only screen and (min-width:${screenSizes.md}px) and (max-width: ${screenSizes.lg}px) {
    .hidden-lg {
      display: none !important;
    }
  } 
    
  @media (min-width:${screenSizes.lg}px) {
    .hidden-xl {
      display: none !important;
    }
  } 
  `
