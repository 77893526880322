import React from "react"
import { StyledLine } from "./Line.styled"

const Line = (props) => {
  return (
    <StyledLine {...props} />
  )
}

export default Line
