import React from 'react'
import TopMenu from '../TopMenu'
import Text from '../../uielements/Text'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Spacer from '../../uielements/Spacer'
import Split from '../../uielements/Split'
import InnerTitle from '../../uielements/InnerTitle'
import { screenSizes } from '../../lib/constants'
import { theme } from '../../theme'

const FooterWrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
    background: #fad7d7;

    @media (max-width: ${screenSizes.md}px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

const FooterRight = styled.div`
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;

    @media (max-width: ${screenSizes.md}px) {
        flex-basis: 100%;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
`

const ContactBlockWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: ${screenSizes.md}px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

const ContactBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 33%;

    > div {
        @media (max-width: ${screenSizes.md}px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
`

const IconsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const ContactBlockText = styled.div`
    display: block;
    margin: 5px 0;
`

const Footer = (props) => {
    const { t } = useTranslation()

    return (
        <>
            <div>
                <Spacer top="8" />
            </div>
            <Spacer top="8" />
            <InnerTitle title="ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΑΖΙ ΜΑΣ" />
            <Spacer top="8" />
            <FooterWrapper>
                <FooterRight>
                    <ContactBlockWrapper>
                        <ContactBlock>
                            <Split>
                                <Spacer
                                    left="1"
                                    right="1"
                                    md={{ left: '0.2', right: '1' }}
                                />
                                <Split align="flex-start" direction="column">
                                    <Text
                                        fw="bold"
                                        textColor={theme.red}
                                        fs="1.4"
                                        tt="uppercase"
                                    >
                                        {' '}
                                        {t('write-to-us')}{' '}
                                    </Text>
                                    <Spacer
                                        left="1"
                                        right="1"
                                        md={{ left: '0.2', right: '1' }}
                                    />
                                    <Text textColor={theme.lightText}>
                                        anadomisi3@gmail.com
                                    </Text>
                                </Split>
                            </Split>
                        </ContactBlock>
                        <Spacer top="1" bottom="1" />
                        <ContactBlock>
                            <Split>
                                <Spacer
                                    left="1"
                                    right="1"
                                    md={{ left: '0.2', right: '1' }}
                                />
                                <Split align="flex-start" direction="column">
                                    <Text
                                        fw="bold"
                                        textColor={theme.red}
                                        fs="1.4"
                                        tt="uppercase"
                                    >
                                        {' '}
                                        {t('call-us')}{' '}
                                    </Text>
                                    <Spacer
                                        left="1"
                                        right="1"
                                        md={{ left: '0.2', right: '1' }}
                                    />
                                    <Text textColor={theme.lightText}>
                                        <ContactBlockText>
                                            {t('city')}: +30 27210 83976
                                        </ContactBlockText>
                                    </Text>
                                </Split>
                            </Split>
                        </ContactBlock>
                        <Spacer top="1" bottom="1" />
                        <ContactBlock>
                            <Split>
                                <Spacer
                                    left="1"
                                    right="1"
                                    md={{ left: '0.2', right: '1' }}
                                />
                                <Split align="flex-start" direction="column">
                                    <Text
                                        fw="bold"
                                        textColor={theme.red}
                                        fs="1.4"
                                        tt="uppercase"
                                    >
                                        {' '}
                                        {t('come-to-us')}{' '}
                                    </Text>
                                    <Spacer
                                        left="1"
                                        right="1"
                                        md={{ left: '0.2', right: '1' }}
                                    />
                                    <Text textColor={theme.lightText}>
                                        <ContactBlockText>
                                            {t('address-field')}
                                        </ContactBlockText>
                                    </Text>
                                </Split>
                            </Split>
                        </ContactBlock>
                    </ContactBlockWrapper>
                </FooterRight>
            </FooterWrapper>
        </>
    )
}

export default Footer
