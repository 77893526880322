import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { theme } from '../theme'
import { GlobalStyles } from '../global'
import styled, { ThemeProvider } from 'styled-components'
import Header from './Header/header'
import { screenSizes } from '../lib/constants'
import Footer from '../sections/Footer'
import '../i18n'
import Copyright from '../sections/Copyright'
import Spacer from '../uielements/Spacer'
import { fontFamily } from '../lib/constants'
import Split from '../uielements/Split'
import Favicon from '../images/icon.png'
import MenuComp from './Menu'
import Wrapper from './Wrapper'

const GlobalWrapper = styled.div`
    padding-top: 80px;

    @media (max-width: ${screenSizes.md}px) {
        padding-top: 80px;
    }
`

const Content = styled.section`
    display: block;
`

const Layout = ({ children }) => {
    const [open, setOpen] = useState(false)

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <link href={fontFamily.sourceSans} rel="stylesheet" />
                <link rel="icon" href={Favicon} type="image/png" />
            </Helmet>
            <MenuComp open={open} setOpen={setOpen} />
            <GlobalWrapper className={open === true ? 'no-overflow' : ''}>
                <GlobalStyles />
                <Header open={open} setOpen={setOpen} />
                <Content>
                    <main>{children}</main>
                </Content>
                <Spacer top="2" bottom="2" />
                <Wrapper>
                    <footer>
                        <Footer />
                        <Copyright>
                            <Split justify="space-between">
                                <div>
                                    Copyright © {new Date().getFullYear()}
                                </div>
                                <div>
                                    Designed & Developed by{' '}
                                    <a href="https://www.yorgo.dev">
                                        yorgo.dev
                                    </a>
                                </div>
                            </Split>
                        </Copyright>
                    </footer>
                </Wrapper>
            </GlobalWrapper>
        </ThemeProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
