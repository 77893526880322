import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import TopMenu from '../../sections/TopMenu'
import { useTranslation } from 'react-i18next'
import { theme } from '../../theme'
import { Link } from 'gatsby'
import Wrapper from '../Wrapper'

const getPathFromMenuType = (item) => {
    const type = item.internal.type
    const slug = item.slug

    switch (type) {
        case 'ContentfulRentalCategories':
            return `/rental/${slug}`
        default:
            return `${slug}`
    }
}

const MenuBox = styled.div`
    background: ${theme.red};
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0;
    z-index: 99;
    transition: width 0.5s ease;
    padding-top: 80px;
    overflow-y: scroll;
    display: flex;

    &.opened {
        width: 100%;
    }

    > div {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    .top-menu-wrapper {
        position: relative;
        @media (max-width: 768px) {
            order: -1;
            margin-top: 20px;
        }

        > div {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            > div {
                @media (max-width: 768px) {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                }

                > div {
                    background: none;
                }
            }

            a {
                color: #fff;
                border-bottom: 1px dashed ${theme.grey};
                padding: 10px 0;
                margin: 30px 0;
                font-size: 1.6em;
                text-decoration: none;

                @media (max-width: 768px) {
                    margin: 15px 0;
                }

                &:hover {
                    span {
                        color: ${theme.grey};
                    }
                }

                span {
                    color: #fff;
                }
            }
        }
    }

    ul {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;

        li {
            text-align: center;
            display: block;
            margin: 10px 0;

            a {
                color: #fff;
                text-decoration: none;
                padding: 15px 0;
                display: inline-block;
                font-size: 2rem;
                font-weight: bold;

                &:hover {
                    color: ${theme.black};
                }

                @media (max-width: 768px) {
                    padding: 4px 0;
                }
            }
        }
    }
`

const TopMenuWrapper = styled.div`
    display: block;
    position: relative;

    @media (max-width: 768px) {
    }
`

const MyMenuComp = ({ data, open, setOpen }) => {
    const { i18n } = useTranslation()
    return (
        <MenuBox className={open ? 'opened' : ''}>
            <div className="mainmenu wrapper">
                {/*<Wrapper>*/}
                {data.menu.nodes.map(
                    (item) =>
                        item.node_locale === i18n.language && (
                            <ul key={item.id}>
                                {item.menuItems.map((menu) => (
                                    <li key={menu.id}>
                                        <Link
                                            to={getPathFromMenuType(menu)}
                                            onClick={() => setOpen(false)}
                                        >
                                            {menu.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )
                )}
                <TopMenuWrapper className="top-menu-wrapper">
                    <TopMenu />
                </TopMenuWrapper>
                {/*</Wrapper>*/}
            </div>
        </MenuBox>
    )
}

export default function MenuComp({ open, setOpen }) {
    return (
        <StaticQuery
            query={graphql`
                query fetchMenu {
                    menu: allContentfulMenus {
                        nodes {
                            id
                            title
                            node_locale
                            menuItems {
                                id
                                title
                                slug
                                node_locale
                                internal {
                                    type
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => (
                <MyMenuComp data={data} open={open} setOpen={setOpen} />
            )}
        ></StaticQuery>
    )
}
