import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Hamburger from '../../uielements/Hamburger'
import Logo from '../../uielements/Logo'
import Spacer from '../../uielements/Spacer'
import TopMenu from '../../sections/TopMenu'
import { useTranslation } from 'react-i18next'
import { FaPhone, FaEnvelope } from 'react-icons/fa'
import { theme } from '../../theme'
import Wrapper from '../Wrapper'
import Link from 'gatsby-link'
import { screenSizes } from '../../lib/constants'

const HeaderDivWrapper = styled.div`
    background: #fff;
    padding: 10px 15px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    height: 80px;
`

const HeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
`

const MenuIconLogoDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const HeaderRightDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
`

const HeaderMenu = styled.div`
    display: flex;
`

const PhoneBox = styled.div`
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 5px;

    a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 20px;
        background: ${theme.red};
        color: ${theme.white};
        border-radius: 4px;
        box-shadow: 0 0 6px #ccc;

        @media (max-width: ${screenSizes.lg}px) {
            padding: 13px 13px;
            font-size: 1rem;
        }

        &:hover {
            background: ${theme.darkRed};
        }
    }
`

const EmailBox = styled.div`
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 5px;

    a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 20px;
        background: ${theme.black};
        color: ${theme.white};
        border-radius: 4px;
        box-shadow: 0 0 6px #ccc;

        @media (max-width: ${screenSizes.lg}px) {
            padding: 13px 13px;
            font-size: 1rem;
        }

        &:hover {
            background: ${theme.darkRed};
        }
    }
`

const TextBlock = styled.div`
    display: block;
    margin-left: 10px;

    @media (max-width: ${screenSizes.md}px) {
        display: none;
    }
`

const Header = ({ open, setOpen }) => {
    const { t } = useTranslation()

    return (
        <header>
            <HeaderDivWrapper>
                <Wrapper>
                    <HeaderDiv>
                        <MenuIconLogoDiv>
                            <Hamburger open={open} setOpen={setOpen} />
                            <Spacer
                                left="1"
                                right="1.4"
                                md={{ left: '0.2', right: '0.2' }}
                            />
                            <Logo width="190px" />
                        </MenuIconLogoDiv>
                        <Spacer left="1" right="1" />

                        <HeaderRightDiv>
                            <HeaderMenu className="hidden-sm hidden-md">
                                <Spacer
                                    left="1"
                                    right="1"
                                    md={{ left: '0.2', right: '0.2' }}
                                />
                            </HeaderMenu>
                            <PhoneBox>
                                <Link to="tel: +302721083976" target="_blank">
                                    <FaPhone />
                                    <TextBlock>
                                        <div>27210 83976</div>
                                    </TextBlock>
                                </Link>
                            </PhoneBox>
                            <EmailBox>
                                <Link
                                    to="mailto: anadomisi3@gmail.com"
                                    target="_blank"
                                >
                                    <FaEnvelope />
                                    <TextBlock>
                                        <div>anadomisi3@gmail.com</div>
                                    </TextBlock>
                                </Link>
                            </EmailBox>

                            {/*<Spacer left="1" right="1" />*/}
                            {/*<Circle*/}
                            {/*    s="40px"*/}
                            {/*    bgColor={theme.red}*/}
                            {/*    onClick={() =>*/}
                            {/*        (window.location.href = '/?lang=en')*/}
                            {/*    }*/}
                            {/*    className={`purpleCircle ${*/}
                            {/*        i18n.language === 'en' ? 'active' : ''*/}
                            {/*    }`}*/}
                            {/*>*/}
                            {/*    {' '}*/}
                            {/*    <strong>EN</strong>{' '}*/}
                            {/*</Circle>*/}
                            {/*<Spacer left="0.6" right="0.6" />*/}
                            {/*<Circle*/}
                            {/*    s="40px"*/}
                            {/*    bgColor={theme.grey}*/}
                            {/*    onClick={() =>*/}
                            {/*        (window.location.href = '/?lang=el')*/}
                            {/*    }*/}
                            {/*    className={`blueCircle ${*/}
                            {/*        i18n.language === 'el' ? 'active' : ''*/}
                            {/*    }`}*/}
                            {/*>*/}
                            {/*    {' '}*/}
                            {/*    <strong>ΕΛ</strong>{' '}*/}
                            {/*</Circle>*/}
                        </HeaderRightDiv>
                    </HeaderDiv>
                </Wrapper>
            </HeaderDivWrapper>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
