import styled from "styled-components";

export const StyledHamburger = styled.button`
  width: 50px;
  height: 32px;
  border: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0;
  background: transparent;
  z-index: 999999;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    cursor: pointer
  }
`
