import React from 'react'
import { StyledHamburger } from './hamburger.styled'
import Line from '../Line'

const Hamburger = ({ open, setOpen }) => {
    return (
        <StyledHamburger className="hamburger" onClick={() => setOpen(!open)}>
            <Line
                className="line-1"
                angle={open ? '45' : '0'}
                bgColor={({ theme }) => (open ? theme.red : theme.red)}
                w="45px"
            />
            <Line
                className="line-2"
                angle={open ? '0' : '0'}
                bgColor={({ theme }) => (open ? theme.red : theme.red)}
                w={open ? '0' : '25px'}
            />
            <Line
                className="line-3"
                angle={open ? '-45' : '0'}
                bgColor={({ theme }) => (open ? theme.red : theme.red)}
                w="35px"
            />
        </StyledHamburger>
    )
}

export default Hamburger
