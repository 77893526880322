import styled from 'styled-components'
import { screenSizes } from '../../lib/constants'

const Copyright = styled.div`
    padding: ${(props) => props.padding};

    @media (max-width: ${screenSizes.md}px) {
        > div {
            @media (max-width: ${screenSizes.md}px) {
                flex-direction: column;
                align-items: flex-start;
            }
            > div {
                padding: 8px;
            }
        }
    }
`

Copyright.defaultProps = {
    padding: '20px 0',
}

export default Copyright
